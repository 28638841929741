import {
  faExternalLinkAlt,
  faFileVideo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Layout from './../../components/Layout';
import SEO from './../../components/Seo';
import queryString from 'querystring';
import ContentfulContent from '../common/ContentfulContent';

const PublicVideoCollection = ({ pageContext, location }: any) => {
  const queryParams = queryString.parse(location.search);

  const { pageData } = pageContext;
  const [currentVideo, setCurrentVideo] = useState(pageData.items[0]);

  useEffect(() => {
    if (queryParams) {
      const videoId = queryParams['?selectedVideo'];
      setCurrentVideo(pageData.items.find((item: any) => item.id === videoId));
    } else {
      setCurrentVideo(pageData.items[0]);
    }
  }, []);

  const videoItemClasses =
    'border-b last:border-0 border-gray-200 first:rounded-t-lg last:rounded-b-lg text-primary p-3 text-lg sans-serif cursor-pointer hover:underline';
  const selectedVideoClasses = 'bg-blue-100 font-bold';

  return (
    <Layout>
      <SEO title="Video Collection" />

      <div className="flex flex-col lg:flex-row px-8 py-4">
        {pageData && (
          <>
            <section className="w-full lg:w-2/3">
              <section
                className="bg-primary p-8 flex flex-col rounded-lg"
                style={{ height: '700px' }}
              >
                <div className="mb-6">
                  <h1 className="text-4xl font-bold text-white">
                    {pageData.name}
                  </h1>
                  {pageData.description && (
                    <div className="mt-1">
                      <ContentfulContent
                        content={pageData.description.description}
                        classNames="text-gray-100 sans-serif"
                      />
                    </div>
                  )}
                </div>
                <section className="mb-2">
                  <h2 className="text-blue-200 text-xl sans-serif font-bold">
                    {currentVideo.name}
                  </h2>
                  {currentVideo.description ? (
                    <div className="mt-1">
                      <ContentfulContent
                        content={currentVideo.description.description}
                        classNames="text-gray-100 sans-serif"
                      />
                    </div>
                  ) : null}
                </section>
                <iframe
                  src={`https://player.vimeo.com/video/${
                    currentVideo.link.split('/')[3]
                  }?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title={currentVideo.name}
                ></iframe>
              </section>
            </section>
            <section className="w-full lg:w-1/3 h-full px-4 mt-6 lg:mt-0">
              <p className="text-gray-600 text-xl sans-serif font-bold mb-1">
                Videos in this Collection
              </p>
              <div className="bg-white rounded-lg shadow-md">
                <ul>
                  {pageData.items
                    .filter((item: any) => !!item.id)
                    .map((video: any, i: number) => (
                      <li
                        className={
                          currentVideo.id === video.id
                            ? `${videoItemClasses} ${selectedVideoClasses}`
                            : `${videoItemClasses}`
                        }
                        onClick={() => setCurrentVideo(video)}
                        key={i}
                      >
                        <p className={'sans-serif'}>
                          <FontAwesomeIcon
                            icon={faFileVideo}
                            className="mr-2"
                          />
                          {video.name}
                        </p>
                      </li>
                    ))}
                </ul>
              </div>
              {!!currentVideo?.supportingDocuments?.length && (
                <div className="mt-8">
                  <p className="text-gray-600 text-xl sans-serif font-bold mb-1">
                    Supporting Documents
                  </p>
                  <>
                    {currentVideo.supportingDocuments.map(
                      (document: any, i: number) => {
                        return (
                          <a
                            href={document.downloadLink}
                            target="_blank"
                            key={i}
                          >
                            <div className="p-4 bg-white shadow-md mb-2 rounded-lg">
                              <span className="text-primary sans-serif text-lg hover:underline hover:text-primary-dark">
                                {document.title}
                              </span>
                              <FontAwesomeIcon
                                className="text-gray-400 text-sm ml-2 "
                                icon={faExternalLinkAlt}
                              />
                            </div>
                          </a>
                        );
                      }
                    )}
                  </>
                </div>
              )}
            </section>
          </>
        )}
      </div>
    </Layout>
  );
};

export default PublicVideoCollection;
